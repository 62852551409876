var favorite = {
	debug : false,
	mylist: [],
	init: function(){
		//console.log("favorite init");
		//debug用にlocalstorageをクリア
		if(favorite.debug){
			console.log("local storage clear");
			localStorage.clear();
		}
		favorite.checkFavorite();
		favorite.list();
		favorite.nav();
	},
	checkFavorite: function(){
		//console.log("check favorite")
		let data = [];
		//一覧をlocalstorageからjson形式に変換
		data.push( JSON.parse( localStorage.getItem('stayplus') ) );
		let $navFavorite = $('#page_nav a[data-page="favorite"]');
		if(data[0] == null){
			$navFavorite.addClass("disable");
		}else{
			if( $navFavorite.hasClass("disable") ){
				$navFavorite.removeClass("disable");
			}
			let dataLen = data[0].length;
			for(var i = 0; i < dataLen; i++){
				let d = data[0][i];
				$('a[data-type="' + d.type + '"]').each(function(){
					let $this 		= $(this);
					let fid 		= parseInt($this.attr("data-favorite-id"));
					let targetID 	= parseInt(d.ID);
					if(fid === targetID){
						$this.addClass("active");
					}
				});
			}
		}
	},
	nav: function(){
		$("a.btn_favorite").off().on("click", function(){
			let $this = $(this),
				type = $(this).attr("data-type"),
				favoriteID = $(this).attr("data-favorite-id");
			let set = { 
				type : type,
				ID 	: favoriteID
			};
			//console.log(set);
			if(!$this.hasClass("active")){
				$this.addClass("active");
				favorite.add(set);
				let category = urlParam.lang;
				let label = type + ":" + favoriteID;
				api.sendEvent("", "favorite", label);
			}else{
				favorite.delete(set);
				let label = type + ":" + favoriteID;
				api.sendEvent("", "unfavorite", label);
			}
		});
	},
	add: function(set){
		//console.log("add favorite:", set, favorite.mylist);
		var arry = favorite.mylist;
		arry.push(set);
		localStorage.setItem('stayplus', JSON.stringify(arry));
		favorite.mylist = arry;

		console.log("after add favorite:", favorite.mylist);
		favorite.list();
	},
	delete: function(set){
		let data = [];
		//一覧をlocalstorageからjson形式に変換
		data.push( JSON.parse( localStorage.getItem('stayplus') ) );
		let dataLen = data[0].length;
		//現在保持している配列から探して削除
		for(var i = 0; i < dataLen; i++){
			let d = data[0][i];
			if(d.type === set.type){
				let thisID = parseInt(d.ID);
				if( thisID === parseInt(set.ID) ){
					var hasNum = i;;
				}
			}
		}

		//activeになってるボタンを処理
		$('a[data-type="' + set.type + '"]').each(function(){
			let $this 		= $(this);
			let fid 		= parseInt($this.attr("data-favorite-id"));
			let targetID 	= parseInt(set.ID);
			if(fid === targetID){
				$this.removeClass("active");
			}
		});
		let farry = data[0];
		farry.splice(hasNum, 1);
		//console.log(farry, farry.length)
		if(farry.length === 0){
			let $navFavorite = $("#page_nav li:eq(2) a");
			$navFavorite.addClass("disable");
		}
		favorite.mylist = farry;
		localStorage.setItem('stayplus', JSON.stringify(farry));
		//console.log("削除後：", farry);
		favorite.list();
	},
	list: function(){
		let $navFavorite = $("#page_nav li:eq(2) a");
		
		let data = [];
		data.push( JSON.parse( localStorage.getItem('stayplus') ) );
		$("#favorite ul#list_favorite li").remove();
		//console.log( data[0], data[0].length )
		if(!data[0]){
			$navFavorite.addClass("disable");
		}else{
			if( $navFavorite.hasClass("disable") ){
				$navFavorite.removeClass("disable");
			}
			favorite.mylist = data[0];
			var listHTML = '';
			for(var i = 0; i < favorite.mylist.length; i++){
				let d 		= favorite.mylist[i];
				let id 		= parseInt(d.ID);
				let type 	= d.type;
				if(type === "deck"){
					var thisData = decks.filter(function(item,index){
						if(item.deck_id === id){
							return item;
						}
					});
				}else if(type === "spot"){
					var thisData = spots.filter(function(item,index){
						if(item.spot_id === id){
							return item;
						}
					});
				}else if(type === "feature"){
					//特集
					$("#list_feature").css("display", "block");
				}
				if(type === "deck" || type === "spot"){
					let data = thisData[0];
					var link = eval("data." + type + "_id");
					if(type === "deck"){
						var place_id = data.deck_id;
					}else if(type === "spot"){
						var place_id = data.spot_id;
					}else{
						var place_id = data.feature_id;
					}
					listHTML += '<li class="list_spot"><a href="' + place_id + '" data-type="' + type + '" title="' + data.name + '"><figure style="background-image: url(' + imgPath + data.image +')"></figure><h4>' + data.name + '</h4></a></li>';
				}
			}
			$("#list_favorite ul").html(listHTML);
			common.nav();
		}
	}
}