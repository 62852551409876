/*

必要な機能
- マップに自分の位置を

2019.05.28
ポジションはポジションの変数をもたせた
posNum 	: 現在のポジション
posMax 	: スポットのdivの最大数
posArry : 3つのspotの並び順

//順序
1.初期表示時は真ん中のスポットのみ読み込み
2.スポット表示後、前後のスポットのファーストビューを読み込み
*/
var posOrder = [
	[0,1,2],
	[1,2,0],
	[2,0,1]
]
var spot = {
	current: 0,
	max: 3,
	aim: "",
	posBefore: 0,//フリックで移動後に前の番号を参照するため
	posNum: 0,
	posMax: 2,
	posArry: [1,2,0],
	canClick: true,
	order: [
		[0,0,0],
		[200,-100,-100],
		[100,100,-200]
	],
	isDeckChange: true,
	getSpot: function(){
		//console.log("現在のスポットID: ", urlParam.spotID);
		spot.current = 0;
		let param 	= {};
		let url 	= "/api/" + urlParam.lang + "/spots/" + urlParam.spotID +".json";
		//初回に来たユーザーと2回目以降とで切り分ける？
		tool.getJson(url, param).done(function(json){
		//	console.log("個別スポット取得",json);
			selectedSpots = [];
			selectedSpots.push(json);
		//	console.log("get spot: before>", urlParam.beforePage)
			if(urlParam.beforePage === "deck"){
				spot.posNum++;
				spot.posNum = spot.posNum > spot.posMax ? 0 : spot.posNum;
			}else{
				/*
					SPOT > SPOTの際、カードの移動をしないといけないので、訪問時にSPOTからくるのと切り分ける必要アリ
					htmlタグにsingleがついていたら、SPOT > SPOT
				*/
				if($html.classList.contains("single")){
					spot.posNum++;
					spot.posNum = spot.posNum > spot.posMax ? 0 : spot.posNum;
				}else{
					spot.posNum = 1;
				}
			}
			spot.aim = "next";
			tool.changeTitle(json.name_en);
			//スポットとデッキを切り分ける
			$html.classList.add("single");
			tool.makeFirstView(selectedSpots, spot.posNum, true).done(function(){
				//console.log("spot: makefirstView done")
				//headerのfavoriteを変更
				let $btnFavorite = $(".spot_nav .btn_favorite");
				$btnFavorite.attr("data-type", "spot");
				$btnFavorite.removeClass("active");
				$btnFavorite.attr("data-favorite-id", urlParam.spotID);
				urlParam.beforePage = urlParam.page;
				spot.change().done(function(){
					//console.log("spot change done")
					spot.openSpot();
					//console.log("get spot and block change complete");
				});
			});
		});
	},
	getDeck: function(){
		let param 	= {};
		let url 	= "/api/" + urlParam.lang + "/decks/" + urlParam.deckID + ".json";
		
		if($html.classList.contains("single")){
			$html.classList.remove("single");
		}
		console.log("getDeck:", urlParam.beforePage, urlParam.page, urlParam.beforeDeckID, urlParam.deckID);
		if(urlParam.beforePage != "spot"){
			//console.log("getDeck: 前のページがスポットではない", urlParam.beforeDeckID, urlParam.deckID);
			if(urlParam.page === "deck" && urlParam.beforePage === "deck" && urlParam.beforeDeckID != urlParam.deckID){
				//deck > deck
				console.log(urlParam.beforeDeckID, urlParam.deckID);
				if(urlParam.beforeDeckID != urlParam.deckID){
					urlParam.beforeDeckID = urlParam.deckID;
					spot.isDeckChange = true;
					spot.otherToDeck(url, param);
				}
			}else{
				if(!$html.classList.contains("active_spot")){
					//初回訪問時にデッキだった時
					//console.log("初回訪問")
					spot.isDeckChange = false;
					tool.getJson(url, param).done(function(json){
						//console.log("デッキ",json);
						tool.changeTitle(json.name);
						selectedSpots = [];
						spot.addDeckInfo(json);
						spot.posNum = 1;
						spot.aim = "next";
						for(var i = 0; i < 3; i++){
							if(i === 2){
								tool.makeFirstView(selectedSpots, i, false).done(function(){
									spot.openSpot();
									spot.nav();
								});
							}else{
								tool.makeFirstView(selectedSpots, i, false);
							}
						}
					});
				}else{
					spot.isDeckChange = false;
					//console.log("同デッキ別スポット", spot.current, spot.posNum)
					api.sendEvent( urlParam.lang, "deck", location.pathname + "?" + location.search.substring(1) );
					spot.move();
				}
			}
		}else{
			//SPOT > DECK
			if(urlParam.page === "deck" && urlParam.beforePage === "spot"){
				spot.otherToDeck(url, param);
			}else{
				//-console.log("ここってくることあるの？-------------------------------------")
				//他ページからdeckへ
				if($html.classList.contains("active_spot")){
					//すでに開いている状態
					spot.change();
				}else{
					openDeck();
				}
			}
		}
		function openDeck(){
			tool.getJson(url, param).done(function(json){
				//console.log("デッキ",json);
				selectedSpots = [];
				spot.addDeckInfo(json);
				tool.makeFirstView(selectedSpots, spot.current, true).done(function(){
					if(urlParam.beforePage === "start"){
						spot.posNum++;
						spot.posNum = spot.posNum > spot.posMax ? 0 : spot.posNum;
						spot.aim 	= "next";
					}
					spot.change().done(function(){
						spot.openSpot();
						spot.nav();
						console.log("get deck and block change complete");
					});
				});
			});
		}
	},
	addDeckInfo(json){
		spot.max 		= json.spots.length;
		selectedSpots 	= json.spots;
		//console.log(selectedSpots)
		//ヘッダーのお気に入り
		let $btnFavorite = $(".spot_nav .btn_favorite");
		$btnFavorite.attr("data-type", "deck");
		$btnFavorite.removeClass("active");
		$btnFavorite.attr("data-favorite-id", urlParam.deckID);
		//console.log("deckID:", json.deck_id)

		$("#header_spot h3").html(json.name);
		$("#small_header h3").html(json.name);

		gMap.makeList(json);
	},
	otherToDeck: function(url, param){
		spot.isDeckChange = true;
		tool.getJson(url, param).done(function(json){
			selectedSpots = [];
			tool.changeTitle(json.name);
			spot.addDeckInfo(json);
			spot.posNum++;
			spot.posNum = spot.posNum > 2 ? 0 : spot.posNum;
			spot.current = 0;
			tool.makeFirstView(selectedSpots, spot.posNum, true).done(function(){
				spot.move();
				spot.nav();
			});
		});
	},
	nav: function(){
		var $blk 	= $(".spot").eq( posOrder[spot.posNum][1] );
		$("a.btn_prev").off().on("click", function(){
			common.scrollToPosition(0, 100);
			setTimeout(function(){
				spot.dec();
			}, 200);
		});
		$("a.btn_next").off().on("click", function(){
			common.scrollToPosition(0, 100);
			setTimeout(function(){
				spot.inc();
			}, 200);
		});
		/*
		$(".ga").off().on("click", function(){
			let category = urlParam.lang;
			let action = $(this).attr("data-ga");
			api.sendEvent(category, action, "");
		});
		*/
		//flick
		if(isSP && urlParam.page != "spot"){
			const limit = 90;
			var $wrap 		= $(".spot").eq(spot.posArry[1]).find(".spot_firstview"),
				$middleSpot = $(".spot").eq( spot.posArry[1] ),
				$nextSpot = $(".spot").eq( spot.posArry[2] ),
				$prevSpot = $(".spot").eq( spot.posArry[0] );
			var flickMin = 50;
			var position,positionX,cx;

			$wrap.off().on({
				'touchstart': function(e) {
					$("#block_spots").addClass("notransition");
					//if(!spot.canClick){ e.preventDefault(); return false;}
					cx 			= 0;
					position 	= getPosition(e);
				},
				'touchmove': function(e) {
					//if(!spot.canClick){ e.preventDefault(); return false;}
					cx 	= position - getPosition(event);
					//画像のtransform用
					if(cx > 0){
						if(cx > flickMin){
							var flicked = cx - flickMin;
							var p 		= flicked/$wrap.width()*100;
							$middleSpot.css({
								"transform": "translate3d(" + ( spot.order[spot.posNum][spot.posArry[1]] + p/-5 ) + "%, 0, 0)"
							});
							$nextSpot.css({
								"transform": "translate3d(" + ( spot.order[spot.posNum][spot.posArry[2]] - p ) + "%, 0, 0)"
							});
						}
					}else{
						if(cx < -flickMin){
							var flicked = cx + flickMin;
							var p 		= flicked/$wrap.width()*100;
							$middleSpot.css({
								"transform": "translate3d(" + ( spot.order[spot.posNum][spot.posArry[1]] + p/-5 ) + "%, 0, 0)"
							});
							$prevSpot.css({
								"transform": "translate3d(" + ( spot.order[spot.posNum][spot.posArry[0]] - p ) + "%, 0, 0)"
							});
						}
					}
				},
				'touchend': function(e) {
					$("#block_spots").removeClass("notransition");

					spot.canFlick = false;
					$wrap.off();
					if(cx < -limit){
						spot.dec();
					}else if(cx > limit){
						spot.inc();
					}else{
						resetPos();
					}
					cx = 0;
				},
				'touchcancel': function(e){
					spot.canFlick = false;
					resetPos();
				}
			});
			function resetPos(){
				cx = 0;
				$("#block_spots").removeClass("notransition");
				$middleSpot.css({ "transform": "" });
				$prevSpot.css({ "transform": "" });
				$nextSpot.css({ "transform": "" });
				setTimeout(function(){
					spot.nav();
				}, 300);
			}
			function getPosition(event){
				return event.touches[0].pageX;
			}
		}
		$("a.btn_link_gmap").off().on("click", function(){
			if(urlParam.page === "deck"){
				api.sendEvent("", "spot_map", "spot:"+ selectedSpots[spot.current].spot_id);
			}else if(urlParam.page === "spot"){
				api.sendEvent("", "spot_map", "spot:"+ selectedSpots[0].spot_id);
			}
		});

		$("a.btn_map").off().on("click", function(){
			//console.log("ここ？")
			if(!$html.classList.contains("show_map")){
				tool.addSpotToMap();
				
				setTimeout(function(){
					$html.classList.add("show_map");
					let category = "Map";
					let label 	= location.pathname + "?" + location.search.substring(1);
					api.sendEvent(category, "deck_map", label);
					gMap.activeSpot(false);
				}, 400);
			}
		});
		$("a#btn_close_map").off().on("click", function(){
			if($html.classList.contains("show_map")){
				$html.classList.remove("show_map");
			}
		});
	},
	getSpotDetail: function(){
		var $blk 	= $(".spot").eq( posOrder[spot.posNum][1] );
		let $more 	= $blk.find(".block_more");
		//api.sendEvent("", "see_more", location.pathname + location.search);
		tool.addSpotDetail().done(function(){
			//スポットのスライドショー init
			for(var i = 0; i < $blk.find(".slideshow").length; i++){
				spotSlide.init(i);
			}
			common.nav();
		});
	},
	inc: function(){
		if(spot.canClick){
			spot.canClick = false;
			spot.posBefore = spot.posNum;
			spot.current++;
			spot.posNum++;
			spot.posNum = spot.posNum > 2 ? 0 : spot.posNum;
			spot.aim = "next";
			spot.current = spot.current >= spot.max ? 0 : spot.current;
			let param = "?hotel_id=" + urlParam.hotelID + "&page=deck&deck_id=" + urlParam.deckID + "&num=" + spot.current;
			urlParam.changeParam(param);
		}
	},
	dec: function(){
		if(spot.canClick){
			spot.canClick = false;
			spot.posBefore = spot.posNum;
			spot.current--;
			spot.posNum--;
			spot.posNum = spot.posNum < 0 ? spot.posMax : spot.posNum;
			spot.aim = "prev";
			spot.current = spot.current < 0 ? spot.max-1 : spot.current;
			let param = "?hotel_id=" + urlParam.hotelID + "&page=deck&deck_id=" + urlParam.deckID + "&num=" + spot.current;
			urlParam.changeParam(param);
		}
	},
	changeCurrentNum: function(){
		//スポットの現在地を入れる
		let $header = $("header#header_spot");
		$header.find(".max").html(spot.max);
		$header.find(".current").html(spot.current+1);
		//$(".spot_nav .btn_favorite").attr("data-favorite-id", urlParam.spotID);
		let $smallHeader = $("#small_header");
		$smallHeader.find(".max").html(spot.max);
		$smallHeader.find(".current").html(spot.current+1);
	},
	change: function(){
		let defer 	= $.Deferred();
		//console.log("spot.change: before>", urlParam.beforePage);
		if(urlParam.beforePage != "spot"){
			if(urlParam.beforePage === "deck" && urlParam.beforePage != "start" && urlParam.beforeDeckID != urlParam.deckID){
				//デッキの移動
				var spotData 	= selectedSpots[0];
				spot.canClick 	= false;
				spot.current 	= 0;
				//現在のスポットからみて、次のスポットに初回のデータを入れる
				tool.makeFirstView(selectedSpots, spot.current, true).done(function(){
					//console.log("spot.change : end make first view デッキの移動:", spot.current)
					spot.posNum++;
					spot.posNum = spot.posNum > spot.posMax ? 0 : spot.posNum;
					spot.aim = "next";
					afterGetData();
				});
			}else{
				//サイトにスポットから訪問した時
				//2019.07.04 OK
				afterGetData();
			}
		}else{
			//console.log("デッキ内移動", urlParam.page, urlParam.beforePage)
			//console.log(selectedSpots[spot.current], spot.current)
			var currentSpotID 	= selectedSpots[spot.current].spot_id;
			var spotData 		= selectedSpots[spot.current];
			//SPOT > DECK
			if(urlParam.page === "deck" && urlParam.beforePage === "spot"){
				console.log("spot > deck")
				tool.makeFirstView(selectedSpots, spot.current, true).done(function(){
					spot.posNum++;
					spot.posNum = spot.posNum > spot.posMax ? 0 : spot.posNum;
					spot.aim = "next";
					afterGetData();
				});
			}else if(urlParam.page === "spot" && urlParam.beforePage === "deck"){
				//return false
				//spot.posNum++;
				//spot.posNum = spot.posNum > spot.posMax ? 0 : spot.posNum;
				spot.aim = "next";
				afterGetData();
			}else if(urlParam.page === "deck" && urlParam.beforePage === "start"){
				//ロード後デッキページだったら
				spot.posNum++;
				spot.posNum = spot.posNum > spot.posMax ? 0 : spot.posNum;
				spot.aim = "next";
				afterGetData();
			}else{
				afterGetData();
			}
			//console.log("spot.change スポット移動：", spot.current, spotData);
		}
		
		function afterGetData(){
			spot.changeCurrentNum();
			//移動
			spot.move().done(function(){
				common.nav();
				defer.resolve();
			});
		}
		return defer.promise();
	},
	move: function(){
		let defer 	= $.Deferred();
		let $blockSpots 	= $("#block_spots");
		//console.log(posOrder, spot.posNum)
		let middle = posOrder[spot.posNum][1];
		let next = middle+1 > spot.posMax ? 0 : middle+1;
		let prev = middle-1 < 0 ? spot.posMax : middle-1;
		spot.posArry = [prev, middle, next];
		//console.log("spot.move:", spot.aim, spot.current, spot.posArry, middle);

		//MOVE ---------------------------------------------------------------
		//デッキが変わった時、スポットからデッキに来た場合は違う挙動をさせたい
		$(".spot").eq(posOrder[spot.posNum][1]).css({
			"transform": "translate3d(" + spot.order[spot.posNum][middle] + "%, 0, 0)"
		});
		
		spot.changeCurrentNum();
		if(urlParam.beforePage === "deck"){
			urlParam.beforeDeckID = urlParam.deckID;
		}
		setTimeout(function(){
			//詳細情報の取得
			spot.getSpotDetail();

			$blockSpots.addClass("notransition");
			//デッキ間移動
			setTimeout(function(){
				$blockSpots.removeClass(function(index, className) {
					return (className.match(/\bpos\S+/g) || []).join(' ');
				});
				$(".spot").css({
					"position": "",
					"top" : "",
					"transform": ""
				});
				$(".spot .block_more").removeClass("active");
				$blockSpots.addClass("pos" + spot.posNum);
				gMap.activeSpot();
				setTimeout(function(){
					spot.afterShowSpot();
				}, 650);
				setTimeout(function(){
					resetSetting();
				},100);
			},50);
		},600);

		function resetSetting(){
			$blockSpots.removeClass("notransition");
			//現在表示されているスポット以外を書き換え
			for(var i = 0; i < 3; i++){
				if($html.classList.contains("show_map")){
					tool.makeFirstView(selectedSpots, i, false);
				}else{
					if(i != 1){
						tool.makeFirstView(selectedSpots, i, false);
					}
				}
				if(i === 2){
					spot.canClick = true;
					spot.nav();
					//現在のページを今までいたページに入れる
					urlParam.beforePage = urlParam.page;
				}
			}
			defer.resolve();
		}
		return defer.promise();
	},
	openSpot: function(){
		spot.changeCurrentNum();
		if(!$html.classList.contains("active_spot")){
			$html.classList.add("active_spot");
			setTimeout(function(){
				$html.classList.add("scroll_spot");
				//詳細情報の取得
				spot.getSpotDetail();
				urlParam.beforePage = urlParam.page;
			},600);
		}
	},
	closeSpot: function(){
		if($html.classList.contains("active_spot")){
			$html.classList.remove("scroll_spot");
			$html.classList.remove("show_small_header");
		}
		if($html.classList.contains("show_map")){
			$html.classList.remove("show_map");
		}
		setTimeout(function(){
			$html.classList.remove("active_spot");
			setTimeout(function(){
				$(".spot .block_more").removeClass("active");
				//spotのブロックのポジションをリセット
				$("#block_spots").removeClass().addClass("pos1");
				spot.posNum = 0;
			},600);
		}, 50);
	},
	afterShowSpot: function(){
		console.log("Finish change spot --------------------------------")
		if($("#loading").is(":visible")){
			$("#loading").fadeOut(200, function(){

			});
		}
	}
}