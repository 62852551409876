var tool = {
	getJson: function(url,params){
		var defer 	= $.Deferred();
		var url 	= url;
		$.ajax({
			type 		: "GET",
			dataType	: 'json',
			url 		: url,
			data 		: params,
			success		: defer.resolve,
			error 		: defer.reject
		});
		return defer.promise();
	},
	changeTitle: function(ttl){
		let sitename = "STAY+";
		if(ttl != ""){
			let t = $('<textarea />').html(ttl).text();
			document.title = t + " | " + sitename;
		}else{
			document.title = sitename;
		}
	},
	makeCondition: function(s){
		//何番目か指定する
		let $blk 	= $(".spot").eq( spot.posArry[1] );
		
		let youbiHTML 	= '';
		var youbis 		= lang_youbi[urlParam.langNum];
		let youbi 		= youbis[parseInt(today.date)];
		let hasTime 	= false;
		for(var i = 0; i < youbis.length; i++){
			youbiHTML += '<li><a href="javascript:void(0);">' + youbis[i] + '</a></li>';
			if(s.busy[i].length >0){
				hasTime = true;
			}
		}
		if(!hasTime){
			$blk.find(".time_condition").addClass("hidden");
		}
		$blk.find(".ttl_condition_day ul.list_youbi").html(youbiHTML);
		$blk.find(".ttl_condition_day h6 .txt").html(spotTexts.busy[urlParam.langNum]);
		$blk.find(".ttl_condition_day a.youbi").html(youbi);
		$blk.find(".ttl_condition_day ul.list_youbi li").eq(today.date).find("a").addClass("active");
		let data = s.busy[today.date];
		let d = new Date(),
			h = d.getHours(),
			currentCondition = 0;
		if(data.length === 0){
			$blk.find(".chart_condition").addClass("disable");
			$blk.find(".info_spot .condition").addClass("disable");
			$blk.find(".chart_condition ul li").each(function(i){
				let $this = $(this);
				$this.find("p").css({
					"height": "0%"
				});
			});
		}else{
			$blk.find(".chart_condition").removeClass("disable");
			$blk.find(".chart_condition ul li").each(function(i){
				let $this = $(this);
				if(i+1 === h){
					currentCondition = data[i];
					$this.addClass("active");
				}
				$this.find("p").css({
					"height": data[i] + "%"
				});
			});
		}

		let condition = spotTexts.txt_busy[urlParam.langNum][Math.floor(currentCondition/25) -1];
		if(currentCondition === 0){
			condition = spotTexts.txt_busy[urlParam.langNum][0];
		}
		//console.log(currentCondition, condition, spotTexts.txt_busy);
		$blk.find(".condition span").html(condition);

		let $list = $blk.find(".ttl_condition_day .list_youbi");
		$blk.find(".ttl_condition_day h6 a").off().on("click", function(){
			$list.animate({
				height: "toggle", opacity: "toggle"
			},{
				duration: 200,
				complete: function(){
					$blk.find(".list_youbi li a").off().on("click", function(){
						let idx = $blk.find(".list_youbi li a").index(this);
						tool.makeSelectCondition(idx);
					});
				}
			});
		});
	},
	makeSelectCondition: function(idx){
		//選択した曜日の混雑状況
		let $blk 	= $(".spot").eq( posOrder[spot.posNum][1] );
		let $list 	= $blk.find(".ttl_condition_day .list_youbi");
		$list.animate({
			height: "toggle", opacity: "toggle"
		},200);

		let data = selectedSpots[spot.current].busy[idx];
		if(data.length != 0){
			if( $blk.find(".chart_condition").hasClass("disable") ){
				$blk.find(".chart_condition").removeClass("disable");
			}
			$blk.find(".chart_condition ul li").each(function(i){
				$(this).find("p").css({
					"height": data[i] + "%"
				});
			});
		}else{
			if( !$blk.find(".chart_condition").hasClass("disable") ){
				$blk.find(".chart_condition").addClass("disable");
			}
			$blk.find(".chart_condition ul li").each(function(i){
				$(this).find("p").css({
					"height": "0%"
				});
			});
		}
		
		$blk.find(".ttl_condition_day a.youbi").html(lang_youbi[urlParam.langNum][idx]);
		$blk.find(".ttl_condition_day ul.list_youbi li a").removeClass("active");
		$blk.find(".ttl_condition_day ul.list_youbi li").eq(idx).find("a").addClass("active");
	},
	addSpotToMap: function(){
		//ホテルの位置
		gMap.init(currentHotel.latlng[0], currentHotel.latlng[1], ".gmap");
		//スポットの位置
		for(var i = 0; i < selectedSpots.length; i++){
			let spot = selectedSpots[i];
			//console.log(spot)
			gMap.addSpot(spot.latlng[0], spot.latlng[1], ".gmap", i, "spot");
		}
		common.getCurrentPosition();
		//全てのスポットが一画面に入るようにフィットさせる
		gMap.map.fitBounds(gMap.bounds);
		gMap.map.panToBounds(gMap.bounds);
	},
	makeFirstView: function(data, i, deckChange){
		//console.log("make firstview", data, i)
		let defer 	= $.Deferred();
		
		if(deckChange){
			//デッキを移動
			var s 		= data[0];
			var $blk 	= $(".spot").eq( posOrder[spot.posNum][1] );
			
		}else{
			if(urlParam.page === "deck"){
				//スポット移動
				var current = spot.current - 1 + i;
				current = current < 0 ? spot.max-1 : current;
				current = current > spot.max-1 ? 0 : current;
				var s = data[current];
				//console.log(spot.posArry[i])
				//console.log($blk)
			}else{
				//スポット単体
				//console.log("makefirstview: スポット単体");
				var s = data[0];
				//console.log(spot.posArry, i)
			}
			var $blk = $(".spot").eq(posOrder[spot.posNum][i]);
		}
		$blk.find(".time_condition").removeClass("hidden");
		$blk.find(".holiday").removeClass("disable");
		$blk.find(".budget").removeClass("disable");
		$blk.find(".price").removeClass("disable");
		$blk.find(".list_info li").removeClass("disable");
		$blk.find(".info_col2").removeClass("disable");
		$blk.find(".option").removeClass("disable");
		$blk.find(".opening_time").removeClass("disable");
		//スクロールトップに戻す？
		$blk.find(".spot_inner").animate({
			scrollTop: "0px"
		},10);
		//メイン画像
		//console.log(s,i,deckChange)
		$blk.find(".spot_main figure").css("background-image", "url(" + imgPath + s.main_img + ")");
		//name,genre
		$blk.find(".spot_main dl dt").html(s.name_en);
		$blk.find(".spot_main dl dd").html(s.name);
		$blk.find(".spot_main p.txt_genre span").html(s.genre);

		$blk.find(".name_spot span").html(s.name_en);
		//2019.08.28 距離削除
		//$blk.find(".distance span").html(s.distance);
		var now = new Date();
		var hour = now.getHours();
		if(hour < 16){
			var budget = s.budget[0];
		}else{
			var budget = s.budget[1];
		}
		if(budget != ""){
			$blk.find(".price span").html(budget);
		}else{
			$blk.find(".price").addClass("disable");
		}

		//$blk.find(".condition span").html(s.condition);
		$blk.find('.btn_favorite[data-type="spot"]').attr("data-favorite-id", s.spot_id);
		/*
		デッキ > view-source:https://stg.stay-plus.jp/en/deck/683?hotel_id=72&num=0
		スポット > https://stg.stay-plus.jp/en/101?hotel_id=72
		*/
		if(urlParam.page === "deck"){
			var url = "https://" + document.domain + "/" + urlParam.lang + "/deck/" + urlParam.deckID + "?hotel_id=" + urlParam.hotelID + "&num=" + spot.current;
		}else if(urlParam.page === "spot"){
			var url = "https://" + document.domain + "/" + urlParam.lang + "/" + urlParam.spotID+ "?hotel_id=" + urlParam.hotelID;
		}else if(urlParam.page === "feature"){
			var url = "https://" + document.domain + "/" + urlParam.lang + "/feature/";
		}
		$("input.share_url").val(url);

		//ループの最後でお気に入りをチェックする
		if(i === 2){
			favorite.checkFavorite();
		}
		//console.log("change", s)
		let imgArry = [imgPath + s.main_img];
		preload.startLoad(imgArry.length, imgArry).done(function(){
			defer.resolve();
		});
		return defer.promise();
	},
	addSpotDetail: function(){
		let defer 	= $.Deferred();
		let s 		= selectedSpots[spot.current];
		let $blk 	= $(".spot").eq( posOrder[spot.posNum][1] );
		//console.log("makeSpotToOpen: ", spot.posArry[spot.posNum], "今のスポットのデータ：", s);

		$blk.find(".spot_inner").animate({
			scrollTop: "0px"
		},10);
		//混雑状況 > tool.js
		tool.makeCondition(s);

		$blk.find(".about_spot h4").html(s.spot_title);
		$blk.find(".about_spot .lead p").html(s.text);

		//list_info
		if(s.num_of_seats != ""){
			$blk.find(".list_info li.seat").find("dd").html(s.num_of_seats);
		}else{
			$blk.find(".list_info li.seat").addClass("disable");
		}
		//smoking
		if(s.smoking != ""){
			$blk.find(".list_info li.smoking").find("dd").html(s.smoking);
		}else{
			$blk.find(".list_info li.smoking").addClass("disable");
		}
		//対応
		if(s.support != ""){
			let support = spotTexts.ttl_support[urlParam.langNum] + '<br />' + s.support;
			$blk.find(".list_info li.supports").find("dd").html(support);
		}else{
			$blk.find(".list_info li.supports").addClass("disable");
		}
		
		//住所
		$blk.find(".address dd").html("〒" + s.zipcode + "<br />" + s.address);
		//電話番号
		if(s.tel != ""){
			$blk.find(".tel dd").html(s.tel);
		}else{
			$blk.find(".tel").addClass("disable");
		}
		//営業時間
		if(s.openinghour != ""){
			$blk.find(".opening_time p.txt").html(s.openinghour);
		}else{
			$blk.find(".opening_time").addClass("disable");
		}
		
		//定休日
		if(s.holiday != ""){
			$blk.find(".holiday dd").html(spotTexts.ttl_holiday[urlParam.langNum] + "<br />" + s.holiday);
		}else{
			$blk.find(".holiday").addClass("disable");
		}
		
		//予算
		var budget 	= s.budget;
		//var noon 	= budget[0] + " JPY/" + spotTexts.txt_person[urlParam.langNum];
		//var night = budget[1] + " JPY/" + spotTexts.txt_person[urlParam.langNum];
		var noon 	= budget[0];
		var night 	= budget[1];
		//console.log("night", night)
		let bHTML = "";
		if(budget[0] != ""){
			bHTML += spotTexts.txt_timezone[urlParam.langNum][0] + " " + noon;
		}
		if( budget[1] != ""){
			if(budget[0] != ""){
				bHTML += '<br />';
			}
			bHTML += spotTexts.txt_timezone[urlParam.langNum][1] + " " + night
		}else{
			if(budget[0] === ""){
				$blk.find(".budget").addClass("disable");
			}
		}
		$blk.find(".budget p.txt").html( bHTML );
		//$blk.find(".budget p.txt").html(spotTexts.txt_timezone[urlParam.langNum][0] + " " + noon + "<br />" + spotTexts.txt_timezone[urlParam.langNum][1] + " " + night);

		//google map url
		let gURL = 'http://maps.google.co.jp/maps?q=' + encodeURI(s.address);
		$blk.find("a.btn_link_gmap").attr("href", gURL);

		//make MENU slideshow
		let slideTitle 	= s.slideshow_title;
		let slides 		= s.slideshow;
		let slideHTML 	= '';
		let navHTML 	= '';
		for(var a = 0; a < slides.length; a++){
			let slidedata = slides[a];
			if(slidedata.caption == null){
				var caption = "";
			}else{
				var caption = "<p>" + slidedata.caption + "</p>";
			}
			let cls = "";
			if(a === 0){
				cls = ' class="active"';
			}
			slideHTML 	+= '<li' + cls + '><figure style="background-image: url(' + imgPath + slidedata.img + ')"></figure>' + caption + '</li>';
			navHTML 	+= '<li><a' + cls + ' href="javascript:void(0);">' + a + '</a></li>';
		}
		$blk.find(".slideshow ul.spot_slideshow").html(slideHTML);

		if(slides.length > 1){
			$blk.find(".nav_slideshow").html(navHTML);
		}
		
		$blk.find(".img_area h4").html(slideTitle);

		//free_area
		$blk.find(".free_area p.lead").html(s.text02);
		//gallery
		$blk.find(".gallery").html(s.gallery);

		//related
		//console.log(s.spot_id, currentHotel);
		/*
			関連する記事
			現在表示しているスポットがデッキに入っているものが先頭にくるようにソートする
		*/
		let relatedData = currentHotel.content;
		let relateHTML 	= '',
			relatedLen 	= relatedData.length,
			relatedList = [];
		
		for(var r = 0; r < relatedLen; r++){
			let related = relatedData[r];
			//console.log(related.list[0])
			let spots 	= related.list[0].spots,
				hasSpot = false;
			for(var i = 0; i < spots.length; i++){
				let id = spots[i];
				if(s.spot_id === id){
					hasSpot = true;
					relatedList.unshift(related.list[0]);
				}
			}
			if(!hasSpot){
				relatedList.push(related.list[0]);
			}
		}
		for(var rr = 0; rr < relatedList.length; rr++){
			let rData = relatedList[rr];
			if(urlParam.deckID != rData.link){
				//console.log(rData)
				relateHTML += '<li><a data-type="deck" href="' + rData.link + '" title="' + rData.text + '"><figure style="background-image: url(' + imgPath + rData.image + ')"></figure><h5>' + rData.title + '</h5></a></li>';
			}
		}
		$blk.find(".related_decks ul").html(relateHTML);

		//option
		let optionHTML = '';
		let options = s.options;
		for(var o = 0; o < options.length; o++){
			var type 	= options[o].type;
			var v 		= options[o].val;
			var ttl 	= "";
			var txt_option = "";
			if(type === "payment"){
				ttl 		= spotTexts.ttl_payment[urlParam.langNum];
				txt_option 	= v;
				optionHTML += '<div class="' + type + ' option"><p class="icon"></p><div class="col_r"><h6>' + ttl + '</h6><p class="txt">' + txt_option + '</p></div></div>';
			}else{
				if(type === "wifi"){
					ttl = spotTexts.ttl_wifi[urlParam.langNum];
				}else if(type === "toilet"){
					ttl = spotTexts.ttl_toilet[urlParam.langNum];
				}else if(type === "duty_free"){
					ttl = spotTexts.ttl_dutyfree[urlParam.langNum];
				}
				optionHTML += '<div class="' + type + ' option"><p class="icon"></p><div class="col_r"><p class="txt">' + ttl + '<span>' + v + '</span></p></div></div>';
			}
		}
		$blk.find(".block_options").html(optionHTML);
		//SNS
		//console.log(s.site_url, s.fb_url, s.insta_url);
		$blk.find(".sns ul li").removeClass("disable");
		if(s.fb_url != ""){
			$blk.find(".sns_facebook").attr("href", s.fb_url);
		}else{
			$blk.find(".sns_facebook").parent().addClass("disable");
		}
		if(s.insta_url != ""){
			$blk.find(".sns_instagram").attr("href", s.insta_url);
		}else{
			$blk.find(".sns_instagram").parent().addClass("disable");
		}
		tool.addRecommendSpot();
		defer.resolve();
		return defer.promise();
	},
	addRecommendSpot: function(){
		let $blk 			= $(".spot").eq( posOrder[spot.posNum][1] );
		let recommend 		= currentHotel.recommend_spots;
		let relatedTitle 	= spotTexts.ttl_recommend[urlParam.langNum]
		$(".block_recommend_spots h4").html(relatedTitle);
		let HTML = '';
		for(var i = 0; i < recommend.length; i++){
			let r = recommend[i];
			var thisData = spots.filter(function(item,index){
				if(item.spot_id === parseInt(r)){
					return item;
				}
			});
			let data = thisData[0];
			if(urlParam.page === "spot" && urlParam.spotID === data.spot_id){

			}else{
				HTML += '<li><a data-type="spot" href="' + data.spot_id + '" title="' + data.name + '"><figure style="background-image: url(' + data.image + ')"></figure><h5>' + data.name + '</h5></a></li>';
			}
			//console.log("recommend spot:", data);
		}
		$blk.find(".recommend_spots ul").html(HTML);
		console.log("add recommend spot -------------------");
	}
}
var langDay = [
	["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
	["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
	["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
	["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
];
var openingDate = ["Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat.", "Holiday"];