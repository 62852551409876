var gMap = {
	map 	: "",
	center 	: "",
	mk 		: "",
	bounds 	: "",
	spotPos : [],
	markers : [],
	infoWindow: "",
	init 	: function(lat, lng, mapBlock){
		let zoom 	= 15;
		gMap.spotPos = [];
		gMap.markers = [];
		let options = {
			center: new google.maps.LatLng(lat, lng),
			zoom: zoom,
			mapTypeId 		: google.maps.MapTypeId.ROADMAP,
			scrollwheel 	: false,
			disableDefaultUI: false,
			streetViewControl: false,
			scaleControl 	: false,
			panControl 		: true,
			zoomControl 	: false,
			overviewMapControl: true,
			mapTypeControl 	: false,
			mapTypeControlOptions: {
				position: google.maps.ControlPosition.TOP_CENTER
			},
			styles: ""
		};
		gMap.map 		= new google.maps.Map( $(mapBlock).get(0), options );
		gMap.infoWindow = new google.maps.InfoWindow({
			content: '<div class="name_hotel"><p>' + currentHotel.name + '</p></div>'
		});
		gMap.bounds = new google.maps.LatLngBounds();
		let marker 	= new google.maps.Marker({
			map 	: gMap.map,
			icon 	: {
				url : "/wp-content/themes/stayplus/assets/images/common/icon_base.png",
				scaledSize : new google.maps.Size(43, 43)
			},
			position: new google.maps.LatLng(lat,lng)
		});
		gMap.infoWindow.open(map, marker);
		gMap.bounds.extend(marker.position);

		google.maps.event.addDomListener(window, "resize", function() {
			let center = gMap.map.getCenter();
			google.maps.event.trigger(gMap.map, "resize");
			gMap.map.panTo(center); 
		});
	},
	addSpot: function(lat, lng, mapBlock, i){
		let latlng = [lat, lng];
		let count = i;
		
		gMap.spotPos.push(latlng);
		gMap.markers[i] = new google.maps.Marker({
			map: gMap.map,
			icon: {
				url : "/wp-content/themes/stayplus/assets/images/common/icon_spot.png",
				scaledSize : new google.maps.Size(27, 37.5)
			},
			position: new google.maps.LatLng(lat,lng)
		});
		google.maps.event.addListener(gMap.markers[count], 'click', (function(marker, i) {
			return function() {
				//alert(spots[i].name)
			}
		})(gMap.markers[count], i));
		gMap.bounds.extend(gMap.markers[i].position);
	},
	addCurrentLocation: function(lat, lng, accuracy){
		let marker = new google.maps.Marker({
			position: new google.maps.LatLng(lat,lng),
			map : gMap.map,
			icon: new google.maps.MarkerImage(
				"/wp-content/themes/stayplus/assets/images/common/icon_me.png",
				new google.maps.Size( 48, 49) , // 画像のオリジナルサイズ
				new google.maps.Point(0,0), // 基点となる位置 ここは変えません
				new google.maps.Point(12,12), // ここの値で位置を調整
				new google.maps.Size( 24, 24.5 ) , // 変更したい画像のサイズ
			)
		});
		new google.maps.Circle({
			map 		: gMap.map,
			center 		: new google.maps.LatLng(lat,lng),
			radius 		: accuracy,
			strokeColor	: '#0088ff',
			strokeOpacity: 0.8,
			strokeWeight: 1,
			fillColor	: '#0088ff',
			fillOpacity	: 0.2
		});
		gMap.bounds.extend(marker.position);
		gMap.map.fitBounds(gMap.bounds);
		gMap.map.panToBounds(gMap.bounds);
	},
	makeList: function(json){
		//マップにスポットを入れる
		let mapSpotHTML = '';
		for(var i = 0; i < json.spots.length; i++){
			let d = json.spots[i];
			mapSpotHTML += '<li><a href="javascript:void(0);" data-index="' + i + '"><figure><img src="' + imgPath + d.main_img + '" alt="' + d.name + '"></figure></a></li>'
		}
		$(".list_spot_map ul").html(mapSpotHTML);
		gMap.nav();
	},
	nav: function(){
		$(".list_spot_map li a").off().on("click", function(){
			let idx = parseInt( $(this).attr("data-index") );
			spot.current = idx;
			//console.log(spot.current, Math.floor(spot.current/3));
			if(idx > 2){
				spot.posNum = idx - 3*Math.floor(spot.current/3);
			}else{
				spot.posNum = spot.current;
			}
			let param = "?hotel_id=" + urlParam.hotelID + "&page=deck&deck=" + urlParam.deckID + "&num=" + spot.current;
			urlParam.changeURL(param);
			//console.log(gMap.spotPos[idx])
			gMap.activeSpot(true);
		});
	},
	selectedSpot: function(idx){
		gMap.map.panTo( new google.maps.LatLng( gMap.spotPos[idx][0], gMap.spotPos[idx][1] ));
		for(var i = 0; i  < gMap.markers.length; i++){
			if(i === idx){
				gMap.markers[i].setOptions({
					icon: {
						url: "/wp-content/themes/stayplus/assets/images/common/icon_spot_active.png",// マーカーの画像を変更
						scaledSize : new google.maps.Size(27, 37.5)
					}
				});
			}else{
				gMap.markers[i].setOptions({
					icon: {
						url: "/wp-content/themes/stayplus/assets/images/common/icon_spot.png",// マーカーの画像を変更
						scaledSize : new google.maps.Size(27, 37.5)
					}
				});
			}
		}
	},
	activeSpot: function(needMove){
		$(".list_spot_map li a").removeClass("active");
		$(".list_spot_map li").eq(spot.current).find("a").addClass("active");
		if(needMove){
			if(gMap.spotPos.length > 0){
				gMap.selectedSpot(spot.current);
			}
		}
	}
}