var api = {
	getHotel: function(){
		let defer 	= $.Deferred();
		let url 	= "/api/" + urlParam.lang + "/hotels.json";

		let param 	= {};
		tool.getJson(url, param).done(function(json){
			//console.log(json);
			var hotelData = json.hotels.filter(function(item,index){
				if(item.hotel_id === urlParam.hotelID){
					return item;
				}
			});
			console.log(json)
			let $info = $("#info_hotel");
			currentHotel = hotelData[0];
		//	console.log(currentHotel)
			let hotelName = currentHotel.name.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'');
			$("h2.hotel_name span").html(hotelName);
			//var langData = eval("currentHotel.info." + urlParam.lang + "[0]");
			$("#bg_hotel").css( "background-image", "url(" + imgPath + currentHotel.image + ")" );
			//$info.find("h4.name_hotel").html(currentHotel.name_ja + "<br />" + currentHotel.place);
			$info.find("h4.name_hotel").html(currentHotel.name_ja);
			$info.find("h4.name_hotel_lang").html(currentHotel.name);
			$info.find(".address_hotel dd").html(currentHotel.address);
			$info.find(".tel_hotel dd").html(currentHotel.tel);
			
			//お気に入りの中のfeature組み立て
			if(currentHotel.feature){
				$("#list_feature figure").css({
					"background-image": "url(" + imgPath + currentHotel.feature.thumbnail + ")"
				});
				$("#list_feature p").html(currentHotel.feature.title);
				
			}
			
			//$("#to_taxi .code_gps").html(currentHotel.gps_code);
			//console.log("ホテル: ",currentHotel);
			
			//make slideshow
			let slides = currentHotel.slideshow;
			let slideHTML = '';
			//console.log(slides.length);
			//console.log(currentHotel);
			if(slides.length > 0){
				for(var i = 0; i < slides.length; i++){
					let s = slides[i];
					let title = s.title.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g,'');
					slideHTML += '<li><a href="' + s.link + '" data-type="' + s.type + '" title="' + title + '"><div class="img"><figure style="background-image: url(' + imgPath + s.image + ')"></div></figure><p>' + s.title + '</p></a></li>'
				}
				$("#slideshow_main .slideshow").html(slideHTML);
			}else{
				$("#home").addClass("no_slideshow");
			}
			
			//make list
			let contents = currentHotel.content;
			var contentHTML = '';
			let imgArry = [];
			for(var j = 0; j < contents.length; j++){
				let content = contents[j];
				contentHTML += '<div class="block_area">'
							+ '<div class="ttl_page"><h3>' + content.name + '</h3></div>'
							+ '<div class="list">'
							+ '<ul class="list_deck">';
				
				for(var k = 0; k < content.list.length; k++){
					let l = content.list[k];
					contentHTML += '<li><a href="' + l.link + '" title="' + l.text + '" data-type="deck"><figure style="background-image: url(' + imgPath + l.image + ')"></figure><h4>' + l.text + '</h4></a></li>';
					if(l.image == null){

					}else{
						imgArry.push(imgPath + l.image);
					}
				}
				contentHTML += '</ul></div></div>';
			}
			$("main#area_content").html(contentHTML);
			if(imgArry.length > 0){
				preload.startLoad(imgArry.length,imgArry).done(function(){
					common.nav();
					setTimeout(function(){
						defer.resolve();
					},300);
				});
			}else{
				common.nav();
				defer.resolve();
			}
		});
		return defer.promise();
	},
	sendPageView: function(){
		let style = "color: #ff2c76;";
		let label = location.pathname + "?" + location.search.substring(1);
		console.log("%cGoogle Analytics:" + label, style);
		//ga('send', 'pageview', label);
		var pageName = urlParam.page;

		dataLayer.push({
			event: 'pageview',
			eventCategory: urlParam.lang,
			eventAction: urlParam.page,
			eventLabel: label
		});
	},
	sendEvent: function(category, action, label){
		console.log("category:", category, " action:", action, " label:", label);
		//ga('send', 'event', category, action, label);
		dataLayer.push({
			event: 'click',
			eventCategory: urlParam.lang,
			eventAction: "click:" + action,
			eventLabel: label
		});
	}
}