//[日本語, 英語, 簡体, 繁体]
var spotTexts = {
	more 		: ["さらに見る", "More", "更多", "看更多"],
	busy 		: ["混雑状況（目安）", "Degree of Congestion", "店内大概的座位或者排队状况", "擁擠狀況"],
	txt_busy 	: [
		["空いている", "やや空いている", "混んでいる", "すごく混んでいる"],
		["Empty", "Fairly Empty", "Crowded", "Very Crowded"],
		["很空，不用排队", "有些空位", "客满", "客满，要排队"],
		["有空位", "稍微有空", "擁擠", "非常擁擠"]
	],
	related_title	: ["関連する記事", "Related Decks", "相关文章", "相關文章"],
	btn_map 		: ["Googleマップで確認", "Confirm location on Google Map", "在谷歌地图上确认", "在Google地圖上確認"],
	btn_favorite	: ["お気に入りに追加", "Add to My Favorites", "添加到收藏夹", "添加到我的最愛"],
	ttl_opening		: ["営業時間", "Business Hour", "营业时间", "營業時間"],
	yesno 			: [
		["あり", "Yes", "有", "有"],
		["なし", "No", "沒有", "沒有"]
	],
	ttl_wifi 			: ["Wifi", "Wi-Fi", "无线网络", "Wifi"],
	ttl_holiday 		: ["定休日", "Closed On ", "定休日", "定休日"],
	ttl_toilet 			: ["店内トイレ", "In-store Toilet", "店内卫生间", "店內的洗手間"],
	ttl_dutyfree 		: ["免税", "Tax Free", "免税", "免稅"],
	ttl_payment 		: ["お支払い情報", "Payment Information", "付款方法", "付款方式"],
	ttl_support 		: ["対応", "Support", "支持", "支持"],
	txt_timezone 		: [
		["昼", "夜"],
		["noon", "night"],
		["中午", "晚"],
		["中午", "晚"]
	],
	ttl_budget 	: ["予算", "Budget", "预算", "預算"],
	txt_person 	: ["人", "person", "中国語で人", "中国語で人"],
	ttl_recommend: ["おすすめスポット", "Recommended Spots", "人氣推薦", "人气推荐"]
}
var lang_youbi = [
	["日曜日","月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
	["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
	["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
	["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
]

function addLangText(){
	var n = urlParam.langNum;
	$(".spot .more a span").html(spotTexts.more[n]);
	$(".spot h6.ttl_condition_day span.txt").html(spotTexts.busy[n]);
	$(".spot .btn_link_gmap span").html(spotTexts.btn_map[n]);
	$(".spot .btn_favorite.btn_black span").html(spotTexts.btn_favorite[n]);
	$(".spot .block_related_deck h4").html(spotTexts.related_title[n]);
	$(".spot .opening_time h6").html(spotTexts.ttl_opening[n]);
	$(".spot .budget.option h6").html(spotTexts.ttl_budget[n]);
}