/*
初期読み込みjson
- hotels.json // すべてのホテルが入ったjson
- decks.json //すべてのデッキが入ったjson
- spots.json //すべてのスポットが入ったjson
*/
var common = {
	canClick: true,
	init: ()=>{
		let resizeFlag,
			resizeTimer = null,
			$body = $("body");
		
		let accessDate = new Date();
		//日曜日：0
		today.date = accessDate.getDay();
		//ロード時アニメーションしてしまうのを回避してるclassをトリ
		$html.classList.remove("loading");
		/*
		あとで参照する用にメモリにスポットとデッキの一覧を読み込み後コンテンツを表示
		*/
		urlParam.getLang();
		let url 	= "/api/" + urlParam.lang + "/spots.json";
		let param 	= {};
		tool.getJson(url, param).done(function(json){
			spots = json.spots;
			let decksURL = "/api/" + urlParam.lang + "/decks.json";
			tool.getJson(decksURL, param).done(function(json){
				decks = json.decks;
				//console.log("get spots and decks jsons");
				common.startContent();
			});
		});

		/* window resize
		----------------------------- */
		let resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};

		$(window).off("resize").on("resize",function(){
			if(!$body.hasClass("resize")){
				$body.addClass("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		});
		$(document).off().on("scroll touchmove", function(){
			common.scrl();
		});
	},
	startContent: function(){
		//console.log("start content")
		urlParam.checkURL();
		common.nav();
		common.resize();
		favorite.init();
		//-console.log(spots);
	},
	nav: ()=>{
		//ホテル選択
		$("#list_hotel li a").off().on("click", function(){
			let hotelID = $(this).attr("data-id");
			console.log(hotelID);
			let param = "?hotel_id=" + hotelID;
			urlParam.changeURL(param);
		});
		//グロナビ
		$("#page_nav a").off().on("click", function(e){
			e.preventDefault();
			if($(this).hasClass("disable")){ return false}
			if($(this).attr("data-page") === "lang"){
				$("#select_lang").animate({
					height: "toggle", opacity: "toggle"
				},{
					"duration": 200,
					"complete": function(){
						if($("#select_lang").is(":visible")){
							let category = urlParam.lang;
							api.sendEvent(category, "globalnav", "言語選択");
						}
					}
				});
			}else{
				if(common.canClick){
					if($("#select_lang").is(":visible")){
						$("#select_lang").animate({
							height: "toggle", opacity: "toggle"
						},200);
					}
					let href = $(this).attr("href");
					if(href === "/"){
						var param = "/" + urlParam.lang + "/?hotel_id=" + urlParam.hotelID + "&page=home";
					}else{
						var param = "/" + urlParam.lang + "/?hotel_id=" + urlParam.hotelID + "&page=" + href
					}
					//console.log(urlParam.lang, param)
					urlParam.changeURL(param);
				}
			}
		});
		//スポットのリンク
		$(".list_spots li a").off().on("click", function(e){
			e.preventDefault();
			let spotID = $(this).attr("href");
			urlParam.changeURLbyType("spot", spotID);
		});
		$(".recommend_spots li a").off().on("click", function(e){
			e.preventDefault();
			$("html,body").animate({
				scrollTop: "0px"
			},10);
			let spotID = $(this).attr("href");
			setTimeout(function(){
				api.sendEvent("", "recommend_spot",  location.pathname  + "?" + location.search.substring(1) + " > " + selectedSpots[spot.current].spot_id);
				urlParam.changeURLbyType("spot", spotID);
			}, 100);
		});

		//スポットのリンク
		$(".list_deck li a").off().on("click", function(e){
			e.preventDefault();
			let deckID = $(this).attr("href");
			urlParam.changeURLbyType("deck", deckID);
		});
		$(".related_decks li a").off().on("click", function(e){
			e.preventDefault();
			$("html,body").animate({
				scrollTop: "0px"
			},10);
			let deckID = $(this).attr("href");
			urlParam.changeURLbyType("deck", deckID);
		});

		//お気に入り
		$("#list_favorite li a").off().on("click", function(e){
			e.preventDefault();
			let id = $(this).attr("href");
			let type = $(this).attr("data-type");
			urlParam.changeURLbyType(type, id);
		});
		$("#list_feature li a").off().on("click", function(e){
			e.preventDefault();
			let id = $(this).attr("href");
			let type = $(this).attr("data-type");
			urlParam.changeURLbyType(type, id);
		});
		$("#feature a.link_spot").off().on("click", function(e){
			e.preventDefault();
			let id 		= $(this).attr("data-id");
			let type 	= $(this).attr("data-type");
			urlParam.changeURLbyType(type, id);
		});
		//言語選択
		$("#select_lang ul li a").off().on("click", function(){
			let lang = $(this).attr("data-lang"),
				param = location.search.substring(1);
			location.href = "/" + lang + "/?" + param;
		});
		//copy url
		
		$("a.btn_share").off().on("click", function(){
			var copyTarget = $("input.share_url")[0];
			copyTarget.select();
			var range = document.createRange();
			range.selectNodeContents(copyTarget);
			var sel = window.getSelection();
			sel.removeAllRanges();
			sel.addRange(range);
			copyTarget.setSelectionRange(0, 999999);
			document.execCommand("copy");
			alert("copied the URL");
		});
	},
	resize: ()=>{
		let winW = window.innerWidth,
			winH = window.innerHeight,
			$body = $("body");

		setTimeout(function(){
			if($body.hasClass("resize")){
				$body.removeClass("resize");
			}
		},300);
	},
	scrollToPosition: (posY, duration) =>{
		let $page 	= $("html,body"),
			ease 	= "easeInOutCubic";
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: duration,
			"easing" 	: ease,
			"complete"	: function(){
			}
		});
	},
	scrl: () =>{
		var topD = $(document).scrollTop(),
			btmD = topD + $(window).height();
		
		if($html.classList.contains("active_spot")){
			if(urlParam.page === "deck" || urlParam.page === "spot"){
				let _h = window.innerHeight,
					$header = $("header#header_spot");
				if(topD >= _h){
					if(!$html.classList.contains("show_small_header")){
						$html.classList.add("show_small_header");
					}
				}else{
					if($html.classList.contains("show_small_header")){
						$html.classList.remove("show_small_header");
					}
				}
			}
		}
		
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	},
	getCurrentPosition: function(){
		if (navigator.geolocation) {
			let latlng = [];
			navigator.geolocation.getCurrentPosition(successCallBack);

			function successCallBack(position){
				gMap.addCurrentLocation(position.coords.latitude, position.coords.longitude, position.coords.accuracy);
			}
		}
	},
	scrollToPosition: (posY, duration) =>{
		var $page 	= $("html,body"),
			ease 	= "easeInOutCubic",
			flag 	= true;
		$page.animate({
			scrollTop : posY + "px"
		},{
			"duration" 	: duration,
			"easing" 	: ease,
			"complete"	: function(){
				
			}
		});
	}
}
document.addEventListener('DOMContentLoaded', function () {
	common.isPage = $("body").attr("id");
	common.init();
});