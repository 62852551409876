var slideTimer;
var slideshow = {
	current: 0,
	max: 0,
	w: 0,
	canFlick: true,
	duration: 5000,
	init: function(){
		slideshow.current 	= 0;
		slideshow.max 		= $("#slideshow_main .slideshow li").length;
		//console.log(slideshow.max, slideshow.current);
		slideshow.nav();
		if(slideshow.max === 1){ return false;}
		//if(slideshow.max < 1){ return false;}
		clearInterval(slideTimer);
		let $slide = $("#slideshow_main ul.slideshow");
		if(slideshow.max > 1){
			let $first = $("#slideshow_main .slideshow li").eq(0).clone();
			let $last = $("#slideshow_main .slideshow li:last").clone();
			$("#slideshow_main .slideshow").prepend($last);
			$("#slideshow_main .slideshow").append($first);
			
			slideshow.checkNav();
			slideshow.resize();
			//ナビゲーション作成
			let navHTML = '';
			for(var i = 0; i < slideshow.max; i++){
				navHTML += '<li><a href="javascript:void(0);">' + i + '</a></li>'
			}
			$("#nav_slideshow ul").html(navHTML);
			slideshow.change();
			slideTimer = setInterval(function(){
				slideshow.inc();
			},slideshow.duration);
		}else{
			if(slideshow.max >= 1){
				if($slide.find("li p")[0].clientHeight === void 0){

				}else{
					let imgH = Math.floor(window.innerWidth*0.872*0.7522);
					let _h = Math.floor( imgH + $slide.find("li p")[0].clientHeight );
					//console.log(_h, imgH,$slide.find("li:eq(0) p")[0].clientHeight)
					$("#slideshow_main .slideshow").find("li").css({
						"height": _h + "px"
					});
					$("#slideshow_main .slideshow").css({
						"height": _h + "px"
					});
					$("#slideshow_main .slideshow").find("li figure").css({
						"height": imgH + "px"
					});
				}
			}else{
				//特集記事がないので何もしない
				console.log("no feature")
			}
		}
	},
	resize: function(){
		if(slideshow.max > 1){
			slideshow.w = Math.floor( window.innerWidth*.872 );
			$("#slideshow_main .slideshow li").each(function(i){
				$(this).css({
					"left": (i-1)*slideshow.w + "px"
				});
			});
		}
		if(slideshow.max != 0){
			let s = slideshow,
				$slide 		= $("#slideshow_main ul.slideshow"),
				$current 	= $("#slideshow_main .slideshow li").eq(s.current+1),
				posX 		= s.current*s.w;
			let imgH = window.innerWidth*0.872*0.7522;
			if(s.max > 1){
				var _h = Math.floor( imgH + $slide.find("li:eq(" + s.current+1 + ") p").outerHeight() );
				$current.css({
					"height": _h + "px"
				});
				$slide.css({
					"height": _h + "px",
					"transform": "translate3d(-" + posX + "px,0,0)"
				});
				$current.find("figure").css({
					"transform": "translate3d(0,0,0)",
					"height": Math.floor(　imgH ) + "px"
				});
			}else{
				var _h = Math.floor( imgH + $slide.find("li p").outerHeight() );
				$("#slideshow_main ul.slideshow li").css({
					"height": _h + "px"
				});
				$("#slideshow_main ul.slideshow").css({
					"height": _h + "px",
					"transform": "translate3d(-" + posX + "px,0,0)"
				});
				$("#slideshow_main ul.slideshow li").find("figure").css({
					"transform": "translate3d(0,0,0)",
					"height": Math.floor(　imgH ) + "px"
				});
			}
		}
		
	},
	nav: function(){
		//slideshow link
		$("#slideshow_main li a").off("click").on("click", function(e){
			e.preventDefault();
			let type 	= $(this).attr("data-type");
			let id 		= $(this).attr("href");
			urlParam.changeURLbyType(type, id);
		});
		var $wrap = $("#slideshow_main"),
			$slide = $("#slideshow_main .slideshow"),
			$slideLi = $slide.find("li");
		slideshow.canFlick = true;
		if(slideshow.max > 1){
			if(isSP){
				const limit = 60;
				var position,positionY,cx,touching,cy,
					angle;
				$wrap.off().on({
					'touchstart': function(e) {
						$slide.addClass("notransition");
						clearInterval(slideTimer);
						if(!slideshow.canFlick){ e.preventDefault(); return false;}
						cx 			= 0;
						position 	= getPosition(e);
					},
					'touchmove': function(e) {
						if(!slideshow.canFlick){ e.preventDefault(); return false;}
						
						cx 	= position - getPosition(event);
						//画像のtransform用
						var p 	= cx/slideshow.w*5;
						$slide.css({
							"transform": "translate3d(" + (slideshow.current*slideshow.w + cx)*-1 + "px,0,0)"
						});
						if(cx > 0){
							var $imgCurrent = $slideLi.eq(slideshow.current+1).find("figure");
							var $imgNext 	= $slideLi.eq(slideshow.current+2).find("figure");
							$imgCurrent.css({
								"transform": "translate3d(" + p*5 + "%,0,0)"
							});
							$imgNext.css({
								"transform": "translate3d(" + (-10 + p) + "%,0,0)"
							});
						}else{
							var $imgCurrent = $slideLi.eq(slideshow.current).find("figure");
							var $imgNext 	= $slideLi.eq(slideshow.current+1).find("figure");
							$imgNext.css({
								"transform": "translate3d(" + p*5 + "%,0,0)"
							});
							$imgCurrent.css({
								"transform": "translate3d(" + (10 - p) + "%,0,0)"
							});
						}
					},
					'touchend': function(e) {
						slideshow.canFlick = false;
						$wrap.off();
						if(cx < -limit){
							slideshow.dec();
						}else if(cx > limit){
							slideshow.inc();
						}else{
							resetPos();
						}
						cx = 0;
					},
					'touchcancel': function(e){
						slideshow.canFlick = false;
						resetPos();
					}
				});
				function resetPos(){
					cx = 0;
					$slide.removeClass("notransition");
					$slide.addClass("back");
					$slide.css({
						"transform": "translate3d(" + (slideshow.current*slideshow.w)*-1 + "px,0,0)"
					});
					$(".slideshow li figure").css({
						"transform": "translate3d(0,0,0)"
					});
					setTimeout(function(){
						$slide.removeClass("back");
						slideshow.nav();
						clearInterval(slideTimer);
						slideTimer = setInterval(function(){
							slideshow.inc();
						},slideshow.duration);
					}, 300);
				}
				function getPosition(event){
					return event.touches[0].pageX;
				}
			}
		}
	},
	inc: function(){
		if(urlParam.page === "home" || urlParam.page === ""){
			slideshow.current++;
			slideshow.change();
		}
	},
	dec: function(){
		slideshow.current--;
		slideshow.change();
	},
	change: function(){
		clearInterval(slideTimer);
		let s = slideshow,
			$slide 		= $("#slideshow_main ul.slideshow"),
			$current 	= $("#slideshow_main .slideshow li").eq(s.current+1),
			posX 		= s.current*s.w;
		
		$slide.addClass("figureback");
		$slide.removeClass("notransition");
		let imgH = window.innerWidth*0.872*0.7522;
		//console.log($current.find("p")[0], slideshow.current)
		if(s.max > 1){
			if($current.find("p").outerHeight() === void 0){
				var _h = Math.floor( imgH + $current.find("p").outerHeight() );
			}else{
				var _h = Math.floor( imgH + $current.find("p").outerHeight() );
			}
			//console.log(_h)
			$current.css({
				"height": _h + "px"
			});
			$slide.css({
				"height": _h + "px",
				"transform": "translate3d(-" + posX + "px,0,0)"
			});
			$current.find("figure").css({
				"transform": "translate3d(0,0,0)",
				"height": Math.floor(　imgH ) + "px"
			});
		}else{
			var _h = Math.floor( imgH + $slide.find("li p")[0].clientHeight );
			$("#slideshow_main ul.slideshow li").css({
				"height": _h + "px"
			});
			$("#slideshow_main ul.slideshow").css({
				"height": _h + "px",
				"transform": "translate3d(-" + posX + "px,0,0)"
			});
			$("#slideshow_main ul.slideshow li").find("figure").css({
				"transform": "translate3d(0,0,0)",
				"height": Math.floor(　imgH ) + "px"
			});
		}

		
		//console.log(s.current, s.max);
		//
		let resetDuration = 650;
		if(s.current >= s.max || s.current < 0){
			if(s.current >= s.max){
				s.current = 0;
			}else if(s.current < 0){
				s.current = s.max-1;
			}
			s.checkNav();
			setTimeout(function(){
				$slide.addClass("notransition");
				setTimeout(function(){
					let posX 	= s.current*s.w;
					$slide.css({
						"transform": "translate3d(" + posX*-1 + "px, 0, 0)"
					});
					s.resetSlide();
				}, 25);
			}, resetDuration);
		}else{
			s.checkNav();
			setTimeout(function(){
				s.resetSlide();
			}, resetDuration);
		}
	},
	checkNav: function(){
		$("#nav_slideshow li a").removeClass("active");
		$("#nav_slideshow li").eq(slideshow.current).find("a").addClass("active");
	},
	resetSlide: function(){
		setTimeout(function(){
			$("#slideshow_main .slideshow li").find("figure").css({
				"transform": "translate3d(0,0,0)"
			});
			$("#slideshow_main ul.slideshow").removeClass("figureback");
			slideshow.nav();
			clearInterval(slideTimer);
			slideTimer = setInterval(function(){
				slideshow.inc();
			},slideshow.duration);
		},50);
	}
}