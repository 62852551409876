const 	ua 		= window.navigator.userAgent.toLowerCase(),
		appver 	= window.navigator.appVersion.toLowerCase();
let isLegacy 	= appver.indexOf("msie 8.0") > -1 || appver.indexOf("msie 9.0") > -1 || appver.indexOf("msie 10.0") > -1,
	isIE 		= ua.indexOf("msie") !== -1 || navigator.userAgent.match(/Trident/),
	isIE11 		= navigator.userAgent.toLowerCase().indexOf('trident/7') > -1,
	isAndroid 	= navigator.userAgent.match(/Android/i),
	isIPhone 	= navigator.userAgent.match(/iPhone/i),
	isSP 		= navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i),
	isIPad 		= navigator.userAgent.match(/iPad/i),
	isFF 		= ua.indexOf("firefox")!== -1,
	isEdge 		= ua.indexOf("applewebkit") > -1 && ua.indexOf("edge") > -1,
	isMobile	= true,
	isSmallScreen = false,
	isPC = true;
const $html = document.documentElement;
if(isAndroid || isIPhone || isIPad){
	isPC = false;
}else{
	isPC = true;
}
var imgPath = "";
getImgPath();
function getImgPath(){
	let d = location.hostname;
	if( d === "imac-pro.local" || d === "mitomacbook-pro.local"){
		//imgPath = "https://stg.stay-plus.jp";
	}
}
//win or mac
if( navigator.userAgent.indexOf("Win") !== -1 ){
	let ary = ["os_windows"];
	if(isFF){
		ary.push("firefox pc");
	}else{
		if(isIE){
			if(isIE11){
				ary.push("ie11 pc");
			}
		}else if(isEdge){
			ary.push("edge pc");
		}else{
		}
	}
	if(!isSP){
		ary.push("pc");
	}
	addClassToBody(ary);
}
if(navigator.userAgent.indexOf("Mac") !== -1 ){
	let ary = ["os_mac"];
	if(!isSP){
		ary.push("pc");
	}
	if(isFF){
		ary.push("firefox");
	}else{
		if (ua.indexOf('safari') > -1 && ua.indexOf('chrome') === -1){
			ary.push("safari");
		}else{
			ary = ["os_mac"];
		}
	}
	addClassToBody(ary);
}
function addClassToBody(ary){
	for(let i = 0; i < ary.length; i++){
		$html.classList.add(ary[i]);
	}
	if(!isSP){
		$html.classList.add("pc");
	}
}
var mouse = [];
var spots = [];
var decks = [];
var currentHotel = [];
var selectedSpots = [];//現在の選ばれているスポット
var pages = ["page_about", "page_favorite"];
var today = {};
var urlParam = {
	lang 		: "",
	langNum 	: 0,
	beforePage 	: "start",
	beforeDeckID 	: "",//URLを解析する前のページ
	page 		: "",
	hotelID 	: 0,
	spotID 		: 0,
	deckID 		: "",
	isFirst 	: true,
	featureID 	: 0,
	changeURL: function(param){
		//console.log(param);
		window.history.pushState(null, null, param);
		urlParam.checkURL();
	},
	changeParam: function(param){
		//console.log(param);
		window.history.pushState(null, null, param);
		urlParam.beforeDeckID = urlParam.deckID;
		urlParam.changePage();
	},
	changeURLbyType: function(type, ID, num){
		console.log(type, ID);
		urlParam.page = type;
		var deckNum = num || 0;
		if(type === "deck"){
			var param = "/" + urlParam.lang + "/?hotel_id=" + urlParam.hotelID + "&page=" + urlParam.page + "&deck_id=" + ID + "&num=" + deckNum;
		}else if(type === "spot"){
			var param = "/" + urlParam.lang + "/?hotel_id=" + urlParam.hotelID + "&page=" + urlParam.page + "&spot=" + ID;
		}else if(type === "feature"){
			//var param = "/" + urlParam.lang + "/?page=feature" + currentHotel.feature.feature_id + "?hotel_id=" + urlParam.hotelID;
			var param = "/" + urlParam.lang + "/?page=feature&feature_id=" + ID + "&hotel_id=" + urlParam.hotelID;
		}
		urlParam.changeURL(param);
	},
	checkURL: function(){
		console.log("checkURL --------------------------");
		let arg 		= new Object,
			parameter 	= location.search.substring(1),
			pair 		= parameter.split('&'),
			isSpot 		= false,
			isDeck 		= false;
		urlParam.getLang();
		if(urlParam.isFirst){
			//スポット
			addLangText();
		}
		
		//スポットのスライドショー解除
		clearInterval(spotSlideTimer);

		if(urlParam.beforePage === "deck"){
			urlParam.beforeDeckID = urlParam.deckID;
		}
		console.log("before page:", urlParam.beforePage);
		if(pair[0] === "" || pair[0].indexOf("ckcachecontrol") > -1){
			console.log("パラメータなし / トップページ")
			urlParam.isFirst = true;
			spot.afterShowSpot();
			tool.changeTitle("");
		}else{
			for(var i = 0; pair[i]; i++) {
				var kv = pair[i].split('=');
				if(kv[0] === "hotel_id"){
					urlParam.hotelID = parseInt(kv[1]);
				}else if(kv[0] === "page"){
					urlParam.page = kv[1];
				}else if(kv[0] === "spot"){
					//スポットの場合
					urlParam.spotID = parseInt(kv[1]);
					isSpot = true;
				}else if(kv[0] === "deck_id"){
					urlParam.deckID = parseInt(kv[1]);
					isDeck = true;
				}else if(kv[0] === "feature_id"){
					urlParam.featureID = parseInt(kv[1]);
				}else if(kv[0] === "num"){
					if(urlParam.beforePage === "deck" && urlParam.beforeDeckID != urlParam.deckID){
						console.log(spot.current, "スポット変更なし")
					}else{
						spot.current = parseInt(kv[1]);
					}
				}
			}
		}
		//console.log(urlParam.beforePage, urlParam.beforeDeckID, " / ", urlParam.page, urlParam.deckID);
		//スポット / デッキではなく、スポットが開いていたら閉じる
		if(urlParam.page != "spot" && urlParam.page != "deck"){
			spot.closeSpot();
		}
		if(urlParam.beforePage === "deck" && urlParam.beforeDeckID != urlParam.deckID){
			console.log("deck to deck")
			urlParam.changePage();
		}else if(urlParam.beforePage === "deck" && urlParam.page === "spot"){
			console.log("deck to spot");
			urlParam.changePage();
		}else if(urlParam.beforePage === "spot" && urlParam.page === "spot"){
			console.log("spot to spot");
			urlParam.changePage();
		}else{
			//- 前のページがdeck,spot以外なので、素直にページの移動
			if(urlParam.isFirst){
				if(urlParam.hotelID != 0){
					urlParam.isFirst = false;
					api.getHotel().done(function(){
						//console.log("ホテルの情報取得済み--------------");
						if( !$html.classList.contains("hotel_selected") ){
							$html.classList.add("hotel_selected");
						
							slideshow.init();
							setTimeout(function(){
								$("#select_hotel").addClass("disable");
								slideshow.resize();
								urlParam.changePage();
								$("body").removeClass("page_select_hotel");
							},600);
						}
					});
				}
			}else{
				urlParam.changePage();
			}
		}
		if( !$html.classList.contains("show_map") ){
			api.sendPageView();
		}
	},
	getLang: function(){
		let path = location.pathname,
			arry = path.split("/");
		urlParam.lang = arry[arry.length-2];
		//-console.log("言語: ",urlParam.lang);
		if(urlParam.lang === "ja"){
			urlParam.langNum = 0;
		}else if(urlParam.lang === "en"){
			urlParam.langNum = 1;
		}else if(urlParam.lang === "zh-cn"){
			urlParam.langNum = 2;
		}else if(urlParam.lang === "zh-tw"){
			urlParam.langNum = 3;
		}else{
			urlParam.langNum = 1;
		}
	},
	changePage: function(){
		if(urlParam.page === "" || urlParam.page === "home"){
			if(!$html.classList.contains("page_home")){
				$("html").removeClass(function(index, className) {
					return (className.match(/\bpage_\S+/g) || []).join(' ');
				});
				$html.classList.add("page_home");
			}
			window.scrollTo(0,0);
			$(".page").removeClass("active");
			$('[data-page]').removeClass("active");
			$('[data-page="home"]').addClass("active");
			urlParam.beforePage = urlParam.page;
			tool.changeTitle("");
			slideshow.resize();
			setTimeout(function(){
				slideshow.resize();
			}, 100);
		}else{
			if($html.classList.contains("page_feature")){
				$html.classList.remove("page_feature");
			}
			if(urlParam.page === "spot"){
				spot.getSpot();
			}else if(urlParam.page === "deck"){
				//SPOT > DECK
				//DECK > DECK
				spot.getDeck();
			}else{
				$("html").removeClass(function(index, className) {
					return (className.match(/\bpage_\S+/g) || []).join(' ');
				});
				$html.classList.add("page_" + urlParam.page);
				urlParam.beforePage = urlParam.page;
				$('[data-page]').removeClass("active");
				$('[data-page="' + urlParam.page + '"]').addClass("active");
				if(urlParam.page === "feature"){
					console.log(currentHotel);
					for(var i = 0; i < currentHotel.features.length; i++){
						//特集
						var fid = currentHotel.features[i].feature_id;
						if(fid === urlParam.featureID){
							$("#feature .section_inner").html(currentHotel.features[i].content);
						}
					}
				}
				setTimeout(function(){
					$(".page").removeClass("active");
					$("#" + urlParam.page).addClass("active");
				},50);
				if(urlParam.page === "feature"){
					var param = "/" + urlParam.lang + "/feature/" + currentHotel.feature.feature_id + "?hotel_id=" + urlParam.hotelID;
					let url = "https://" + document.domain + param;
					$("input.share_url").val(url);
					let len = $("#feature .slideshow").length;
					for(var i = 0; i < len; i++){
						spotSlide.init(i);
					}
					favorite.init();
					common.nav();
				}else if(urlParam.page === "about"){
					tool.changeTitle(currentHotel.name);
				}
			}
		}
		setTimeout(function(){
			spot.afterShowSpot();
		},600);
	}
}