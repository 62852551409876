var ss = {
	current: 0,
	max: 0
}
var sss = [];
var spotSlideTimer;
var spotSlide = {
	duration: 3000,
	spotNum: 0,
	init: function(num){
		spotSlide.spotNum = spot.posArry[1];
		sss = [];
		clearInterval(spotSlideTimer);
		if(urlParam.page === "spot" || urlParam.page === "deck"){
			var $blk = $(".spot").eq(spotSlide.spotNum);
		}else{
			var $blk = $("#feature");
		}
		$blk.find(".slideshow").each(function(i){
			let _s = object(ss);
			_s.current = 0;
			_s.max = $(this).find("li").length;
			sss.push(_s);
			var navHTML = '';
			if(_s.max > 1){
				for(var j = 0; j < _s.max; j++){
					if(j === 0){
						navHTML += '<li><a class="active" href="javascript:void(0);" title="' + j + '">' + j + '</a></li>';
					}else{
						navHTML += '<li><a href="javascript:void(0);" title="' + j + '">' + j + '</a></li>';
					}
				}
			}else{
				$blk.find(".nav_slideshow").eq(i).remove();
			}
			$blk.find(".nav_slideshow").eq(i).find("ul").html(navHTML);
		});
		
		spotSlide.resize(num);
		setTimeout(function(){
			spotSlide.resize(num);
		},1000);
		//console.log(sss);
		spotSlideTimer = setInterval(function(){
			//num: 何番目のspot
			//0 : 何個目のslideshow
			for(var i = 0; i < sss.length; i++){
				if(sss[i].max > 1){
					spotSlide.inc(i);
				}
			}
		}, spotSlide.duration);
	},
	resize: function(slideNum){
		//console.log("resize:", sss[slideNum])
		if(urlParam.page === "spot" || urlParam.page === "deck"){
			var $slideUl = $(".spot").eq(spotSlide.spotNum).find(".spot_slideshow").eq(slideNum);
			var $slide = $(".spot").eq(spotSlide.spotNum).find(".spot_slideshow").eq(slideNum).find("li").eq(sss[slideNum].current);
		}else{
			var $slideUl 	= $("#feature").find(".spot_slideshow").eq(slideNum);
			var $slide 		= $("#feature").find(".spot_slideshow").eq(slideNum).find("li").eq(sss[slideNum].current);
		}
		let imgH = $slide.find("figure").outerHeight();
		let txtH = $slide.find("p").outerHeight();
		if(txtH === void 0){
			txtH = 0;
		}
		let blockH = Math.floor(imgH + txtH);
		$slideUl.css({
			"height": blockH + "px"
		});
		$slide.css({
			"height": blockH + "px"
		});
	},
	inc: function(slideNum){
		let _s = sss[slideNum];
		_s.current++;
		if(_s.current >= _s.max){
			_s.current = 0;
		}
		spotSlide.change(slideNum);
	},
	change: function(slideNum){
		if(urlParam.page === "spot" || urlParam.page === "deck"){
			var $slide = $(".spot").eq( spot.posArry[1] ).find(".spot_slideshow").eq(slideNum);
			var $slideNav = $(".spot").eq(spotSlide.spotNum).find(".nav_slideshow");
		}else{
			var $slide = $("#feature").find(".spot_slideshow").eq(slideNum);
			var $slideNav = $("#feature").find(".nav_slideshow").eq(slideNum);
		}
		
		//console.log("スライドショー、ブロックNo.", spot.posArry[1])
		$slide.find("li").removeClass("active");
		$slide.find("li").eq(sss[slideNum].current).addClass("active");
		
		$slideNav.find("li a").removeClass("active");
		$slideNav.find("li").eq(sss[slideNum].current).find("a").addClass("active");
		spotSlide.resize(slideNum);
	}
}